import * as ReactDOM from "react-dom";
import * as React from "react";
import { LogoIcon } from "@bw/bw-components";
import { useUserinfo } from "./useUserinfo";
import "./assets/style.css";
import "@bw/bw-components/dist/bw-components.css";
import { OutlinedPrimaryButton } from "./lib/buttons";

function ErrorView() {
    const { error } = Object.fromEntries(new URLSearchParams(window.location.search));
    const url = "www.barentswatch.no/kontakt-oss/driftsstatus/";
    const https_url = "https://" + url;
    return (
        <div className="main">
            <LogoIcon className="logo" />
            <h1>En feil har inntruffet</h1>
            <p>
                {error === "Not Found" ? (
                    "Fant ikke siden du prøver å gå til"
                ) : (
                    <>
                        Hvis feilen vedvarer, ta kontakt med din organisasjon eller se driftsstatus på{" "}
                        <a style={{ whiteSpace: "nowrap" }} href={https_url}>
                            {url}
                        </a>
                    </>
                )}
            </p>
            <a href={"/"} style={{ textDecoration: "none" }}>
                <OutlinedPrimaryButton>Gå til forsida</OutlinedPrimaryButton>
            </a>
        </div>
    );
}

export function ErrorPage() {
    const { userinfo, loading } = useUserinfo();
    return (
        <div id="app">
            <header>
                {userinfo && (
                    <>
                        <div className="userName">{userinfo && userinfo.fullName}</div>
                        <a className={"logout"} href="/api/login/endsession">
                            Logg ut
                        </a>
                    </>
                )}
            </header>
            <main id="appMain">{loading ? <div /> : <ErrorView />}</main>
            <footer />
        </div>
    );
}

ReactDOM.render(<ErrorPage />, document.getElementById("appRoot"));
